<template>
  <div class="breadcrumbtree">
    <ul>
      <li v-for="(item, index) in breadcrumbs" :key="index" class="functional" :class="item.link ? 'link' : 'not_link'"
          @click.prevent="item.link ? routerRedirect(item.route) : null"
      ><a href="#">{{ item.title }}</a></li>
    </ul>
  </div>
</template>

<script setup>
import {ref, inject, onMounted, nextTick} from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";

const route = useRoute();
const router = useRouter();

const store = useStore();
const apiPath = inject('apiPath');

let breadcrumbs = ref([]);

getBreadcrumbs();
async function getBreadcrumbs() {
  if (route.name === 'Home') {
    breadcrumbs.value = [{'title': 'Home', 'link': true, 'route': '/'}];
    return;// [{'title': 'Home', 'link': false}]
  } else if (route.name === 'Select') {
    breadcrumbs.value = [{'title': 'Home', 'link': true, 'route': '/'}, {'title': 'Go To', 'link': true, 'route': '/select'}];
    return;// [{'title': 'Home', 'link': false}]
  } else if (route.name === undefined || route.params === undefined || route.params.id === undefined) {
    breadcrumbs.value = [];
    return;
  }

  let routeName = '';
  switch (route.name.toLowerCase()) {
    case "machine":
      routeName = 'location';
      break;
    case "section":
      routeName = 'section';
      break;
    case "article":
      routeName = 'article';
      break;
  }

  let url = `entity/${routeName}/breadcrumbs`;

  let formData = new FormData();
  formData.append('token', store.state.usertoken);
  formData.append('id', route.params.id);

  return await fetch(`${apiPath}` + url, {
    method: 'POST',
    body: formData,
  }).then((res) => res.json()).then((response) => {
    if (response.result) {
      breadcrumbs.value = [{'title': 'Home', 'link': true, 'route': '/'}];
      response.result.forEach((element) => {
        switch (element.entity) {
          case "location":
            element.route = '/machine/';
            element.link = Boolean(element.lib);
            break;
          case "section":
            element.route = '/section/';
            element.link = true;
            break;
          case "article":
            element.route = '/article/';
            element.link = true;
            break;
        }
        element.route += element.id;
        breadcrumbs.value.push(element);
      });
      //breadcrumbs.value = response.result;
    } else {
      console.log('Looks like there was a problem: \n', response.error);
      if (response.error === 'Wrong token') {
        window.location.reload();
      }
      breadcrumbs.value = [];
    }
  }).catch((error) => {
    console.log('Looks like there was a problem: \n', error);
    breadcrumbs.value = [];
  });
}
function routerRedirect(link) {
  console.log('wtf', link, router);
  router.push(link);
  store.state.breadcrumbPopup = false;
}

</script>


<style lang="scss" scoped>
.breadcrumbtree {
  overflow-y: auto;
  display: flex;
  ul {
    margin: 0px;
    padding-top: 0px;
    overflow: hidden;
    padding-left: 34px;
  }

  li {
    margin: 8px 0;
    position: relative;
    list-style-type: none;
    a {
      text-decoration: none;
      color: #787878;
      padding-left: 14px;
      display: inline-block;
      width: calc(100% - 20px);
      white-space: nowrap;
    }
  }

  li::before{
    content: '';
    display: block;
    position: absolute;
    left: -13px;
    height: 6px;
    width: 6px;
    background-color: #006C3E;
    border-radius: 3px;
    top: calc(50% - 3px);
  }
  .not_link{
    cursor: default;
    a{
      cursor: default;
    }
  }
  .not_link::before{
    background-color: #BCBCBC;
  }

  li:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    left: -10.5px;
    height: calc(100% - 8.5px);
    bottom: -50%;
    width: 1px;
    background-color: #BCBCBC;
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px) and (min-height: 961px) and (orientation: portrait) {
  .breadcrumbtree {
    top: 0px;
  }
}
@media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 500px) and (orientation: landscape){
  .breadcrumbtree {
    ul{
      width: 100%;
      overflow-y: scroll;
    }
  }
}
</style>
