import Session from 'supertokens-web-js/recipe/session';
import SuperTokens from 'supertokens-web-js';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';

export class SuperTokensController {
    static authLink = 'https://auth2.wave7.cloud';

    static serverLink = '';

    static currentTenant = window.location.hostname.split('.')[0];

    static async init() {
        SuperTokens.init({
            appInfo: {
                apiDomain: this.authLink,
                apiBasePath: "/auth",
                appName: "auth",
            },
            recipeList: [
                Session.init({
                    exposeAccessTokenToFrontendInCookieBasedAuth: true,
                    cookieSameSite: "strict",
                }),
                EmailPassword.init(),
            ],
        });
        const self = this;
        await fetch('/config.json').then(res => res.json()).then(async function (config) {
            self.serverLink = config.api;
        });
    }

    static async fetchAuthPost(link, data = {}) {
        return await this.sendFetch(this.authLink + link, data);
    }

    static async fetchServerPost(link, data = {}) {
        return await this.sendFetch(this.serverLink + link, data);
    }

    static async sendFetch(link, data = {}) {
        return await fetch(link, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((response) => {
            return response;
        }).catch((error) => {
            console.log(`Looks like there was a problem with fetching data from ${link}. Error: \n`, error);
            return false;
        });
    }

    static async addRole(role, tenant = this.currentTenant) {
        return await this.fetchAuthPost('/auth/addRole', {"tenant": tenant, "role": role}).then((response) => {
            return response;
        });
    }

    static async setUserRoles(userId, roles, tenant = this.currentTenant) {
        return await this.fetchAuthPost('/auth/setUserRoles', {"userId": userId, "tenant": tenant, "roles": roles}).then((response) => {
            return response;
        });
    }

    static async deleteRole(role, tenant = this.currentTenant) {
        return await this.fetchAuthPost('/auth/deleteRole', {"tenant": tenant, "role": role}).then((response) => {
            return response;
        });
    }

    static async getTenantRoles(tenant = this.currentTenant) {
        return await this.fetchAuthPost('/data/getTenantRoles', {"tenant": tenant}).then((response) => {
            return response;
        });
    }

    static async getTenantUsers(tenant = this.currentTenant) {
        return await this.fetchAuthPost('/auth/getUsers', {"tenant": tenant}).then((response) => {
            return response;
        });
    }

    static async deleteTenantUser(userId, tenant = this.currentTenant) {
        return await this.fetchAuthPost('/auth/deleteTenantUser', {"userId": userId, "tenant": tenant}).then((response) => {
            return response;
        });
    }

    static async getUsers() {
        return await this.fetchAuthPost('/auth/getUsers').then((response) => {
            return response;
        });
    }

    // static async addUser(data) {
    //     if (data.email !== '') {
    //         return this.createUser(data.email, data.password).then((result) => {
    //             return result.data.user;
    //         });
    //     } else {
    //         return false;
    //     }
    // }

    static async addUser(data) {
        return await this.fetchAuthPost('/auth/createUser', {'tenant': this.currentTenant, 'email': data.email, 'password': (data.password === undefined ? this.randomPassword() : data.password)}).then((response) => {
            return response.userId;
        });
    }

    static async checkEmailExist(email) {
        return await this.fetchAuthPost('/auth/checkEmailExist', {'email': email}).then((response) => {
            return response.result;
        });
    }

    static async deleteUser(id) {
        return await this.fetchAuthPost('/auth/deleteUser', {"userid": id}).then((response) => {
            console.log("DELETE-USER", response);
            return response;
        }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
        });
    }

    static async getUser(id) {
        return await this.fetchAuthPost('/data/getUserInfo', {"userid": id}).then((response) => {
            return response;
        }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
        });
    }

    static async getCurrentUser() {
        return await this.fetchAuthPost('/auth/getCurrentUserInfo', {'tenant': this.currentTenant}).then((response) => {
            return response;
        }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
        });
    }

    static async getUserMetadata(id) {
        return await this.getUser(id).then((response) => {
            return response.metadata;
        }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
        });
    }

    static async setUserMetadata(id, data) {
        data.userid = id;
        return await this.fetchAuthPost('/auth/setMetadataByUserId', data).then((response) => {
            return response;
        }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
        });
    }

    static async setCurrentUserMetadata(data) {
        return await this.fetchAuthPost('/auth/setMetadata', data).then((response) => {
            return response;
        }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
        });
    }

    static async changeCurrentUserPassword(oldPassword, newPassword) {
        return await this.fetchAuthPost('/auth/changePassword', {
            'oldPassword': oldPassword,
            'newPassword': newPassword
        }).then((response) => {
            return response;
        }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
        });
    }

    static async signIn(email, password) {
        if (!this.validateEmail(email)) {
            return {
                'result': false,
                'message': 'invalid email/login',
                'formFieldName': 'email',
                'status': 'FIELD_ERROR'
            };
        }
        if (this.isEmpty(password)) {
            return {'result': false, 'message': 'empty password', 'formFieldName': 'password', 'status': 'FIELD_ERROR'};
        }
        try {
            console.log('checkEmailPassword', typeof EmailPassword, EmailPassword);
            let response = await EmailPassword.signIn({
                formFields: [{
                    id: "email",
                    value: email
                }, {
                    id: "password",
                    value: password
                }]
            });
            console.log('wtfSignIn', email, password, response);

            switch (response.status) {
                case 'FIELD_ERROR':
                    response.formFields.forEach(formField => {
                        return {
                            'result': false,
                            'message': 'invalid ' + formField.id + '. ' + formField.error,
                            'formFieldName': formField,
                            'status': response.status
                        };
                    });
                    return {
                        'result': false,
                        'message': 'The ST server gave an unexpected response'
                    };
                case 'WRONG_CREDENTIALS_ERROR':
                    return {
                        'result': false,
                        'message': 'Email password combination is incorrect',
                        'status': response.status
                    };
                default:
                    return {'result': true, 'message': 'Success', 'data': response.user};
            }
        } catch (err) {
            console.log('wtfSignInError: ', err, ' Data - email and password: ', email, password);
            if (err.isSuperTokensGeneralError === true) {
                return {'result': false, 'message': err.message};
            } else {
                return {'result': false, 'message': 'Oops! Something went wrong'};
            }
        }
    }

    static async logout() {
        localStorage.removeItem("bOpus_user");
        localStorage.removeItem("exposedTreeNodes");
        localStorage.removeItem('edit');
        localStorage.removeItem('local-images');
        await Session.signOut().then(() => {
            window.location.href = "/";
        }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
        });
    }

    static async sendPasswordResetEmail(email) {
        if (!this.validateEmail(email)) {
            return {
                'result': false,
                'message': 'invalid email'
            };
        }
        try {
            let response = await EmailPassword.sendPasswordResetEmail({
                formFields: [{
                    id: "email",
                    value: email
                }]
            });

            switch (response.status) {
                case 'FIELD_ERROR':
                    response.formFields.forEach(formField => {
                        return {
                            'result': false,
                            'message': 'invalid ' + formField.id + '. ' + formField.error
                        };
                    });
                    return {
                        'result': false,
                        'message': 'The ST server gave an unexpected response'
                    };
                default:
                    return {'result': true, 'message': 'Please check your email for the password reset link'};
            }
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                return {'result': false, 'message': err.message};
            } else {
                return {'result': false, 'message': 'Oops! Something went wrong'};
            }
        }
    }

    static async signUp(email, password = undefined) {
        if (!this.validateEmail(email)) {
            return {'result': false, 'message': 'invalid email/login'};
        }
        try {
            let response = await EmailPassword.signUp({
                formFields: [
                    {
                        id: "email",
                        value: email
                    },
                    {
                        id: "password",
                        value: password === undefined ? this.randomPassword() : password
                    },
                ]
            });
            if (response.status === "FIELD_ERROR") {
                response.formFields.forEach(formField => {
                    return {'result': false, 'message': 'invalid ' + formField.id + '. ' + formField.error};
                });
                return {'result': false, 'message': 'Oops! Something went wrong'};
            }
            return {'result': true, 'message': 'Success!', 'userId': response};
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                return {'result': false, 'message': err.message};
            }
            return {'result': false, 'message': 'Oops! Something went wrong'};
        }
    }

    static randomPassword() {
        return Math.random().toString(36).slice(2) +
            Math.random().toString(36)
                .toUpperCase().slice(2);
    }

    static validateEmail(email) {
        return email.match(
            /^([A-Za-z\d_\-.])+@([A-Za-z\d_\-.])+\.([A-Za-z]{2,4})$/
        );
    }

    static isEmpty(variable) {
        if (variable === null || variable === undefined) {
            return true;
        }
        if (typeof variable === 'string' && variable.trim() === '') {
            return true;
        }
        if (Array.isArray(variable) && variable.length === 0) {
            return true;
        }

        return typeof variable === 'object' && Object.keys(variable).length === 0;
    }
}